import {I18n} from "i18n-js";
import {getUnit} from "@/utility/helper";
/*import th from "./lang/th/save-search.json" ;// @ts-ignore
import en from "./lang/en/save-search.json";*/
const unit = getUnit();
export const i18nMain = new I18n({
  "en": {
    hello: "Hi!",
    for_sale: "For Sale",
    for_rent: "For Rent",
    min_land_size: "Land %{min}+ " + unit,
    max_land_size: "Land 0-%{max} " + unit,
    min_max_land_size: "Land %{min}-%{max} " + unit,
    max_interior_size: "Interior 0-%{max} " + unit,
    min_interior_size: "Interior %{min}+ " + unit,
    min_max_interior_size: "Interior %{min}-%{max} " + unit,
    map_area: "Map area",
    short_beds: "%{beds}+ Beds",
    short_baths: "%{baths}+ Baths",
    instant: "Immediately",
    daily: "Daily",
    weekly: "Weekly",
    never: "Never",
    failed: 'Something went wrong. Please try again.',
    loginSuccess: 'Login successful',
    success: 'Successful',
    listing: 'Listing',
    types: {
      condo: "Condo",
      house: "House",
      townhouse: "Townhouse",
      land: "Land",
      hotel: "Hotel",
      warehouse: "Warehouse",
      office: "Office",
      apartment: "Apartment",
      villa: "Villa",
      building: "Building",
      shop: "Shop",
    },
    features: {
      "pet-friendly": "Pet Friendly",
      "wi-fi": "Wi-Fi",
      "pool": "Pool",
      "garden": "Garden",
      "24-hour-security": "24 Hour Security",
      "home-office": "Home Office",
      "parking": "Parking",
      "gym": "Gym",
      "aircon": "Air Conditioning",
      "fully-furnished": "Fully Furnished",
    },
    roles: {
      "property-seeker": "Property Seeker",
      "home-owner": "Home Owner",
      "freelance-agent": "Freelance Agent",
      "property-agency": "Property Agency",
      "developer": "Developer",
    },
    attributes: {
      "phone-number": "Phone Number",
    },
    errors: {
      "data-invalid": "The given data was invalid.",
      "data-invalid-attribute": "Invalid %{attr}",
      "required": "Please fill the information",
      'max-budget': 'Max budget cannot be less than min.',
      "budget-required": "Please select budget"
    },
    successData: {
      "send-contact-success": "Send Contact Success",
      "register-success": 'Register success',
      "buyer-enquiry-success": "Thank you, one of our agents should be in touch with you soon!"
    },
    infoData: {
      "reservation-already": "You already reservation"
    },
    'resend-verify-email': 'Resend verify email',
  },
  "ar": {
    hello: "مرحبا!",
    for_sale: "للبيع",
    for_rent: "للإيجار",
    min_land_size: "الأرض %{min}+ " + unit,
    max_land_size: "الأرض 0-%{max} " + unit,
    min_max_land_size: "الأرض %{min}-%{max} " + unit,
    max_interior_size: "الداخل 0-%{max} " + unit,
    min_interior_size: "الداخل %{min}+ " + unit,
    min_max_interior_size: "الداخل %{min}-%{max} " + unit,
    map_area: "منطقة الخريطة",
    short_beds: "%{beds}+ أسرة",
    short_baths: "%{baths}+ حمامات",
    instant: "على الفور",
    daily: "يوميا",
    weekly: "أسبوعي",
    never: "أبدا",
    failed: 'حدث خطأ ما. الرجاء المحاولة مرة أخرى.',
    loginSuccess: 'تم تسجيل الدخول بنجاح',
    success: 'ناجح',
    listing: 'قائمة',
    types: {
      condo: "شقة",
      house: "منزل",
      townhouse: "بيت في البلدة",
      land: "أرض",
      hotel: "فندق",
      warehouse: "مستودع",
      office: "مكتب",
      apartment: "شقة",
      villa: "فيلا",
      building: "مبنى",
      shop: "متجر",
    },
    features: {
      "pet-friendly": "مرحب بالحيوانات الأليفة",
      "wi-fi": "واي فاي",
      "pool": "حمام سباحة",
      "garden": "حديقة",
      "24-hour-security": "أمن 24 ساعة",
      "home-office": "مكتب في المنزل",
      "parking": "موقف سيارات",
      "gym": "صالة رياضية",
      "aircon": "تكييف الهواء",
      "fully-furnished": "مفروش بالكامل",
    },
    roles: {
      "property-seeker": "الباحث عن العقار",
      "home-owner": "مالك المنزل",
      "freelance-agent": "وكيل مستقل",
      "property-agency": "وكالة عقارية",
      "developer": "مطور",
    },
    attributes: {
      "phone-number": "رقم الهاتف",
    },
    errors: {
      "data-invalid": "البيانات المعطاة غير صالحة.",
      "data-invalid-attribute": "%{attr} غير صالح",
      "required": "الرجاء ملء المعلومات",
      "max-budget": "الحد الأقصى للميزانية لا يمكن أن يكون أقل من الحد الأدنى.",
      "budget-required": "الرجاء تحديد الميزانية"
    },
    successData: {
      "send-contact-success": "إرسال الاتصال بنجاح",
      "register-success": 'التسجيل ناجح',
      "buyer-enquiry-success": "شكرا لك، يجب أن يتواصل معك أحد وكلائنا قريبا!"
    },
    infoData: {
      "reservation-already": "لقد قمت بالحجز بالفعل"
    },
    'resend-verify-email': 'إعادة إرسال البريد الإلكتروني للتحقق',
  },
  "th": {
    hello: "สวัสดีจ้า!",
    for_sale: "สำหรับขาย",
    for_rent: "สำหรับเช่า",
    min_land_size: "ที่ดิน %{min}+ ตร.ม.",
    max_land_size: "ที่ดิน 0-%{max} ตร.ม.",
    min_max_land_size: "ที่ดิน %{min}-%{max} ตร.ม.",
    max_interior_size: "พื้นที่ใช้สอย 0-%{max} ตร.ม.",
    min_interior_size: "พื้นที่ใช้สอย %{min}+ ตร.ม.",
    min_max_interior_size: "พื้นที่ใช้สอย %{min}-%{max} ตร.ม.",
    map_area: "ใช้งานแผนที่",
    short_beds: "%{beds}+ ห้องนอน",
    short_baths: "%{baths}+ ห้องน้ำ",
    instant: "ทันที",
    daily: "รายวัน",
    weekly: "รายสัปดาห์",
    never: "ไม่ต้องแจ้งเตือน",
    failed: 'มีข้อผิดพลาดกรุณาลองใหม่อีกครั้ง.',
    loginSuccess: 'เข้าสู่ระบบสำเร็จ',
    success: 'ดำเนินการสำเร็จ',
    listing: 'รายการ',
    types: {
      condo: "คอนโด",
      house: "บ้าน",
      townhouse: "ทาวน์เฮ้าส์",
      land: "ที่ดิน",
      hotel: "โรงแรม",
      warehouse: "คลังสินค้า",
      office: "ออฟฟิศ",
      apartment: "อพาร์ทเม้นท์",
      villa: "วิลล่า",
      building: "อาคาร",
      shop: "ร้านค้า",
    },
    features: {
      "pet-friendly": "สามารถเลี้ยงสัตว์ได้",
      "wi-fi": "Wi-Fi",
      "pool": "สระว่ายน้ำ",
      "garden": "สวน",
      "24-hour-security": "ระบบรักษาความปลอดภัย 24 ชั่วโมง",
      "home-office": "ที่ทำงานที่บ้าน",
      "parking": "ที่จอดรถ",
      "gym": "ห้องออกกำลังกาย",
      "aircon": "เครื่องปรับอากาศ",
      "fully-furnished": "ตกแต่งครบถ้วน",
    },
    roles: {
      "property-seeker": "ผู้ค้นหาอสังหาฯ",
      "home-owner": "เจ้าของอสังหาฯ",
      "freelance-agent": "ฟรีแลนซ์เอเจนท์",
      "property-agency": "เอเจนซี่",
      "developer": "ดีวอลลอปเปอร์",
    },
    attributes: {
      "phone-number": "หมายเลขโทรศัพท์"
    },
    errors: {
      "data-invalid": "ข้อมูลไม่ถูกต้อง",
      "data-invalid-attribute": "%{attr} ไม่ถูกต้อง",
      "required": "กรุณากรอกข้อมูล",
      'max-budget': 'ราคาสูงสุดต้องไม่น้อยกว่าราคาต่ำสุด',
      "budget-required": "กรุณาเลือกราคา"
    },
    successData: {
      "send-contact-success": "ส่งข้อมูลสำเร็จ",
      "register-success": 'ลงทะเบียนสำเร็จ',
      "buyer-enquiry-success": "ขอบคุณ, เอเจนท์ของเราจะติดต่อกลับไปโดยเร็วที่สุด!"
    },
    infoData: {
      "reservation-already": "ลงทะเบียนแล้ว"
    },
    'resend-verify-email': 'ส่งอีเมล์ยืนยันอีกครั้ง',
  },
});

i18nMain.defaultLocale = "en";




